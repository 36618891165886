import React from "react";
import CommonBanner from "../../components/CommonBanner";
import AllServicesCommonSection from "../../components/AllServicesCommonSection";
import PreFooterSection from "../../components/PreFooterSection";
import Footer from "../../components/Footer";
import { HealthcareStaffingData } from "../../constant/service";

const HealthcareStaffing = () => {
  const header =
    "BNI Healthcare specializes in connecting you with a skilled and talented medical workforce, catering to diverse opportunities and ensuring you find the perfect fit for your needs.";
  const strong = "";
  const description =
    "BNI Healthcare boasts a team of experts dedicated to crafting specialized staffing solutions for healthcare organizations. Our recruitment services span a broad spectrum of needs, from physicians and locums to registered nurses, allied healthcare professionals, and healthcare assistants.";

  return (
    <div>
      {/* <Navbar /> */}
      <CommonBanner
        currentPage="HEALTHCARE STAFFING"
        heading="HEALTHCARE STAFFING"
      />
      <AllServicesCommonSection
        header={header}
        strong={strong}
        description={description}
      />
      <div
        style={{
          padding: "0 4%",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="webdesigndevelopment_section_card_container">
          {HealthcareStaffingData.map((item, i) => {
            return (
              <div className="webdesigndevelopment_card_container" key={i}>
                <div className="webdesigndevelopment__card_icon">
                  <img src={item.icon} alt="" srcSet="" />
                </div>
                <div className="section_3_card_title">{item.title}</div>
                <div className="webdesigndevelopment_card_description">
                  {item.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <PreFooterSection />
      <Footer />
    </div>
  );
};

export default HealthcareStaffing;
