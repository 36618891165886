import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import ScrollToTopWrapper from "./ScrollTopWrapper";

import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Careers from "../pages/Careers/Careers";
import ContactUs from "../pages/ContactUs/ContactUs";
import Services from "../pages/Services/Services";
import News from "../pages/News/News";
import ItTransformation from "../pages/Services/ItTransformation";
import CloudTransition from "../pages/Services/CloudTransition";
import WebDesignAndDevelopment from "../pages/Services/WebDesignAndDevelopment";
import SoftwareDevelopment from "../pages/Services/SoftwareDevelopment";
import ApplicationSecurity from "../pages/Services/ApplicationSecurity";
import DatabaseManagement from "../pages/Services/DatabaseManagement";
import CustomisedFinancialSupport from "../pages/Services/CustomisedFinancialSupport";
import AccountingServices from "../pages/Services/AccountingServices";
import Search from "../pages/Search/Search";
import Error from "../pages/Error/Error";
import Login from "../pages/Admin/LoginSignUp/Login";
import SignUp from "../pages/Admin/LoginSignUp/Signup";
import BlogPage from "../pages/BlogPage/BlogPage";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import UserPost from "../pages/Admin/UserPost/UserPost";
import UserSinglePost from "../pages/Admin/UserPost/UserSinglePost";
import ITProfessionalStaffing from "../pages/Services/ITProfessionalStaffing";
import Terms from "../pages/Terms/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Navbar from "../components/Navbar";
import Client from "../pages/Admin/Client/Client";
import ResetPassword from "../pages/Admin/ResetPassword/ResetPassword";
import ForgetPassword from "../pages/Admin/LoginSignUp/ForgetPassword";
import HealthcareStaffing from "../pages/Services/HealthcareStaffing";

const Routers = () => {
  return (
    // <HashRouter>
    <Router>
      <Navbar />
      <ScrollToTopWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/admin">
            <Route index={true} element={<Dashboard />} />
            <Route path="login" element={<Login />} />
            <Route path="forgetpassword" element={<ForgetPassword />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="post/:id" element={<UserSinglePost />} />
            <Route path="post" element={<UserPost />} />
            <Route path="client" element={<Client />} />
            <Route path="*" element={<Error />} />
          </Route>
          {/* <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/signup" element={<SignUp />} />
          <Route path="/admin/post/:id" element={<UserSinglePost />} />
          <Route path="/admin/post" element={<UserPost />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/services">
            <Route index={true} element={<Services />} />
            <Route
              path="healthcarestaffing"
              element={<HealthcareStaffing />}
            />
            <Route path="ittransformation" element={<ItTransformation />} />
            <Route path="cloudtransition" element={<CloudTransition />} />
            <Route
              path="webdesignanddevelopment"
              element={<WebDesignAndDevelopment />}
            />
            <Route
              path="softwaredevelopment"
              element={<SoftwareDevelopment />}
            />
            <Route
              path="itprofessionalstaffing"
              element={<ITProfessionalStaffing />}
            />
            <Route
              path="applicationsecurity"
              element={<ApplicationSecurity />}
            />
            <Route path="databasemanagement" element={<DatabaseManagement />} />
            <Route
              path="customisedfinancialproduct"
              element={<CustomisedFinancialSupport />}
            />
            <Route path="accountingservices" element={<AccountingServices />} />
          </Route>
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<BlogPage />} />
          <Route path="/search" element={<Search />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </ScrollToTopWrapper>
    </Router>
  );
};

export default Routers;
